import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Link from '../link';
import slugify from '../../utils/slugify';

const Menu = ({ children }) => {
    const data = useStaticQuery(graphql`
        query {
            services: allStrapiServices {
                nodes {
                    serviceName
                    id
                }
            }
        }
    `)
    return <ul id="main-menu">
        <li className="active"><Link to="/">Start</Link></li>
        <li><Link to="/onas">O nas</Link></li>
        <li className="has-submenu"><Link to="/oferta">Oferta</Link>
            <ul>
                {
                   data.services.nodes.map(service => (
                        <li key={service.id} >
                            <Link to={`/oferta/${slugify(service.serviceName)}`}>
                                {service.serviceName}
                            </Link>
                        </li>
                    ))
                }
            </ul>
        </li>
        <li><Link to="/blog">Blog</Link></li>
        <li><Link to="/kariera">Kariera</Link></li>
        <li><Link to="/kontakt">Kontakt</Link></li>
        { children }
    </ul>
}

export default Menu;