import React from 'react';

import './container.scss';

const Container = ({children, fluid}) => {
    return <div className="container" style={{maxWidth: fluid ? `100%` : `1440px`}}>
        {children}
    </div>
}

export default Container;