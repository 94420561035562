import React from 'react';

import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faPhoneAlt, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';

const TopInfos = () => {
    return <ul>
        <li><Icon icon={faEnvelope}/>biuro@konto-jarocin.pl</li>
        <li><Icon icon={faPhoneAlt}/>509 94 88 11</li>
        <li><Icon icon={faMapMarkerAlt}/>Jarmarczna 16, Jarocin</li>
    </ul>
}

export default TopInfos;