import React from 'react';
import AniLink from 'gatsby-plugin-transition-link/AniLink';

const handleMenuClose = () => {
    document.querySelector('body').classList.remove('menu-visible');
}

const Link = props => {
    return <AniLink {...props} cover bg="#DEC82E" onClick={handleMenuClose}>{props.children}</AniLink>
}

export default Link;