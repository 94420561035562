import React from 'react';

import Menu from '../menu/menu';
import TopInfos from './topinfos';
import Link from '../link';

import './header.scss';
import logo from '.././assets/logo.png';

const handleToggleClick = e => {
    document.querySelector('body').classList.toggle('menu-visible');
}

const Header = ({transparent}) => {
    
    return <div className={`main-header ${transparent ? 'transparent' : ''}`}>
        <div className="header-left-area">
            <img className="logo" src={logo} alt="Konto" />
        </div>
        <div className="header-right-area">
            <div className="menu-toggle-button" onClick={handleToggleClick}>
                <div className="hamburger"></div>
            </div>
            <div className="menu-wrapper">
                <div className="contact-info">
                    <TopInfos />
                </div>
                <div className="top-menu">
                    <Menu>
                        <li className="line-button" style={{fontWeight: '400'}}><Link to="/kontakt">Zapytaj o wycenę</Link></li>
                    </Menu>
                </div>
            </div>
        </div>
    </div>
}

export default Header;