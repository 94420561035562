import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import slugify from '../../utils/slugify';
import Link from '../link';

import Container from '../container/container';
import Menu from './../menu/menu';
import './footer.scss';

const Footer = () => {
    const data = useStaticQuery(graphql`
        query {
            services: allStrapiServices {
                nodes {
                    serviceName
                    id
                }
            }
        }
    `)
    return (
        <footer className="main-footer">
                <div className="footer-top">
                    <Container>
                        <div className="footer-services">
                            <h3 className="border">Oferta</h3>
                            <ul>
                                {
                                    data.services.nodes.map(service => (
                                        <li key={service.id}>
                                            <Link to={`/oferta/${slugify(service.serviceName)}`}>
                                                {service.serviceName}
                                            </Link>
                                        </li>
                                    ))
                                }
                            </ul>
                        </div>
                        <div className="footer-menu">
                            <h3 className="border">Nawigacja</h3>
                            <Menu>
                                <li><Link to="/polityka-prywatnosci">Polityka prywatności</Link></li>
                            </Menu>
                        </div>
                    </Container>
                </div>
                <div className="footer-bottom">
                    <Container>
                        <div className="copy">© {new Date().getFullYear()} Biuro Usług Finansowo-Księgowych "KONTO". Wszelkie prawa zastrzeżone.</div>
                        <div className="author">Projekt i wykonanie BPWEB.pl</div>
                    </Container>
                </div>
        </footer>
    )
}

export default Footer;